import { FC, useCallback, useMemo, useState } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ShareDashboardModal.module.scss";
import { ConfirmModal } from "src/features";
import { Button, Checkbox, Form, Switch } from "src/components";
import { DASHBOARD_DATE_RANGE_LABEL, GRAY, GREEN } from "src/constants";
import { generateDashboardShareLinkToken } from "src/store/dashboards/dashboardsApi";
import {
  triggerGtmEvent,
  showToastNotification,
  createDashboardShareLink,
  formatToMonthFullYearDate,
} from "src/utils";
import {
  selectAvailableDashboardById,
  selectDashboardDateRangeById,
  selectDefaultDashboardDateRangeByTrackersCollectionId,
} from "src/store/selectors";
import {
  Lock,
  Loader,
  LinkOutline,
  RadioButtonCheck,
  RadioButtonEmpty,
} from "src/assets/icons";

type Props = {
  dashboardId: Dashboard.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const ShareDashboardModal: FC<Props> = ({
  dashboardId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const defaultDashboardDateRange = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeByTrackersCollectionId(state, dashboardId),
  );

  const [isUpdateAllowed, setIsUpdateAllowed] = useState<boolean>(true);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const [selectedDashboardDateRangeId, setSelectedDashboardDateRangeId] =
    useState<DashboardDateRange.Data["id"]>(dashboardDateRangeId);

  const isDashboardDateRangeDefault = useMemo<boolean>(() => {
    if (!dashboardDateRange || !defaultDashboardDateRange) return false;

    return dashboardDateRange.id === defaultDashboardDateRange.id;
  }, [dashboardDateRange, defaultDashboardDateRange]);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isDashboardDateRangeSelected = useMemo<boolean>(() => {
    if (!dashboardDateRangeId || !selectedDashboardDateRangeId) return false;

    return dashboardDateRangeId === selectedDashboardDateRangeId;
  }, [dashboardDateRangeId, selectedDashboardDateRangeId]);

  const isDefaultDashboardDateRangeSelected = useMemo<boolean>(() => {
    if (!defaultDashboardDateRange?.id || !selectedDashboardDateRangeId)
      return false;

    return defaultDashboardDateRange?.id === selectedDashboardDateRangeId;
  }, [defaultDashboardDateRange?.id, selectedDashboardDateRangeId]);

  const getDashboardDateRangeLabel = useCallback(
    (dashboardDateRange: DashboardDateRange.Data): string => {
      if (dashboardDateRange?.type)
        return t(DASHBOARD_DATE_RANGE_LABEL[dashboardDateRange.type]);

      if (dashboardDateRange?.startDate && dashboardDateRange?.endDate) {
        const [formattedStartDate, formattedEndDate] = [
          formatToMonthFullYearDate(new Date(dashboardDateRange.startDate)),
          formatToMonthFullYearDate(new Date(dashboardDateRange.endDate)),
        ];

        return t("page.dashboard.label.dashboard_date_range", {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }

      return "";
    },
    [t],
  );

  const onDashboardDateRangeClick = (): void => {
    if (!dashboardDateRange || !defaultDashboardDateRange) return;

    if (isDashboardDateRangeSelected)
      return setSelectedDashboardDateRangeId(defaultDashboardDateRange.id);

    setSelectedDashboardDateRangeId(dashboardDateRangeId);
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setLoadingStatus("loading");

      const token = await generateDashboardShareLinkToken({
        dashboardId,
        isUpdateAllowed,
        dashboardDateRangeId: selectedDashboardDateRangeId,
      });

      const generatedShareLink = createDashboardShareLink(token);

      copy(generatedShareLink);

      triggerGtmEvent("DashboardCopyShareLink", { dashboardId });

      setLoadingStatus("succeeded");

      showToastNotification({
        id: "share-dashboard-link",
        type: "success",
        text: t("component.modal.share_dashboard.status.success.link_copied"),
      });
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  return (
    <ConfirmModal
      id="share-dashboard"
      type="info"
      className={styles.modalWrapper}
      isLoading={isLoading}
      title={
        <div className={styles.title}>
          <span>{t("component.modal.share_dashboard.title")}</span>
          <span>{dashboard?.name || ""}</span>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <Form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.section}>
            <span className={styles.label}>
              {t("component.modal.share_dashboard.label.access_type")}
            </span>
            <div className={styles.value}>
              <Lock />
              <span>
                {t("component.modal.share_dashboard.label.read_only")}
              </span>
            </div>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>
              {t("component.modal.share_dashboard.label.date_range")}
            </span>
            <div className={styles.dashboardDateRanges}>
              {!isDashboardDateRangeDefault && dashboardDateRange ? (
                <div
                  onClick={onDashboardDateRangeClick}
                  className={styles.dashboardDateRange}
                >
                  <Checkbox
                    isDisabled={isLoading}
                    className={styles.checkbox}
                    elementChecked={<RadioButtonCheck />}
                    elementUnchecked={<RadioButtonEmpty />}
                    isChecked={isDashboardDateRangeSelected}
                  />
                  <div className={styles.dashboardDateRangeLabel}>
                    <span>
                      {t(
                        "component.modal.share_dashboard.label.selected_period",
                      )}
                    </span>
                    <span className={styles.dashboardDateRangeSubLabel}>
                      {getDashboardDateRangeLabel(dashboardDateRange)}
                    </span>
                  </div>
                </div>
              ) : null}
              {defaultDashboardDateRange ? (
                <div
                  onClick={onDashboardDateRangeClick}
                  className={
                    isDashboardDateRangeDefault ? "" : styles.dashboardDateRange
                  }
                >
                  {!isDashboardDateRangeDefault && (
                    <Checkbox
                      isDisabled={isLoading}
                      className={styles.checkbox}
                      elementChecked={<RadioButtonCheck />}
                      elementUnchecked={<RadioButtonEmpty />}
                      isChecked={isDefaultDashboardDateRangeSelected}
                    />
                  )}
                  <span>
                    {getDashboardDateRangeLabel(defaultDashboardDateRange)}
                  </span>
                </div>
              ) : null}
            </div>
            <div className={styles.text}>
              {t("component.modal.share_dashboard.label.default_date_range")}
            </div>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>
              {t("component.modal.share_dashboard.label.data_update")}
            </span>
            <div className={styles.value}>
              <Switch
                onColor={GREEN}
                offColor={GRAY}
                disabled={isLoading}
                checked={isUpdateAllowed}
                onChange={() => setIsUpdateAllowed(!isUpdateAllowed)}
              />
              <span>
                {t("component.modal.share_dashboard.label.update_allowed")}
              </span>
            </div>
          </div>
          <div className={styles.section}>
            <Button
              type="submit"
              buttonSize="large"
              disabled={isLoading}
              className={styles.button}
            >
              {isLoading ? (
                <Loader className={styles.loader} />
              ) : (
                <>
                  <LinkOutline />
                </>
              )}
              <span>{t("component.modal.share_dashboard.button.submit")}</span>
            </Button>
          </div>
        </Form>
      </div>
    </ConfirmModal>
  );
};
