import { ForwardedRef, RefObject } from "react";

import { showDevelopmentError } from "src/utils";

// Inner imports
import { GetElementParamsReturnType } from "./types";

export const getElementParams = (
  elementRef: RefObject<HTMLElement> | ForwardedRef<HTMLElement>,
): GetElementParamsReturnType => {
  const params: GetElementParamsReturnType = {
    element: null,
    height: 0,
    width: 0,
    bottom: 0,
    right: 0,
  };

  if (!elementRef || !("current" in elementRef)) return params;

  try {
    const { current } = elementRef || {};
    const { clientHeight = 0, clientWidth = 0 } = current || {};
    const { bottom = 0, right = 0 } = current?.getBoundingClientRect() || {};

    params.element = current;
    params.height = clientHeight;
    params.width = clientWidth;
    params.bottom = bottom;
    params.right = right;
  } catch (error) {
    const errorTitle = "MENU DROPDOWN REF ERROR";

    showDevelopmentError({ additionalTexts: [errorTitle], error });
  }

  return params;
};

export const getClientParams = () => {
  const { clientHeight, clientWidth } = document.documentElement;

  return {
    height: clientHeight,
    width: clientWidth,
  };
};
