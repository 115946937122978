import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTracker.module.scss";
import { TrackerKeywordsDataSourcesIcon } from "src/features";
import { selectLocationById, selectLanguageById } from "src/store/selectors";

type Props = {
  tracker: Tracker.CreationData;
};

export const SelectedTracker: FC<Props> = ({ tracker }) => {
  const { t } = useTranslation();

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, tracker.locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, tracker.languageId),
  );

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.heading}>
          {t("component.selected_tracker.label.tracker")}
        </span>
        <span className={styles.title}>{tracker.name}</span>
        <span className={styles.description}>{tracker.description}</span>
      </div>
      <div className={styles.settings}>
        <div className={styles.keywordsDataSource}>
          <TrackerKeywordsDataSourcesIcon
            keywordsDataSources={tracker.keywordsDataSources}
          />
        </div>
        <div className={styles.location} title={locationName}>
          <span>{locationName}</span>
        </div>
        <div className={styles.language} title={languageName}>
          <span>{tracker.languageId.toUpperCase()}</span>
        </div>
      </div>
    </div>
  );
};
