import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectSearchesSection.module.scss";
import { useAppDispatch } from "src/store";
import { Button, Form } from "src/components";
import { generateDocId } from "src/store/utils";
import { removeSearches } from "src/store/actions";
import { isSearchCreatedTypeGuard } from "src/utils";
import {
  SEARCH_DEFAULT_STATUS,
  SEARCH_DEFAULT_DESCRIPTION,
} from "src/constants";
import {
  useLanguageId,
  useLocationId,
  useResetScrollPosition,
} from "src/hooks";

// Inner imports
import { useSelectedTrackerSearches } from "./hooks";
import {
  SelectedSearches,
  SelectedTracker,
  SuggestedSearches,
} from "./components";

type Props = {
  tracker: Tracker.CreationData;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  submitHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
};

export const SelectSearchesSection: FC<Props> = ({
  tracker,
  submitHandler,
  keywordsDataSource,
  locationId: defaultLocationId,
  languageId: defaultLanguageId,
  selectedSearches: defaultSelectedSearches,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    selectSearch,
    updateSearch,
    unselectSearch,
    searchKeywords,
    selectedSearches,
    isSearchesChanged,
    unselectedSearches,
    updateSearchKeywords,
  } = useSelectedTrackerSearches({
    trackerId: tracker.id,
    selectedSearches: defaultSelectedSearches,
  });

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: defaultLocationId,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: defaultLanguageId,
  });

  const isSubmitShown = useMemo<boolean>(
    () => Boolean(selectedSearches.length),
    [selectedSearches.length],
  );

  const isEverySearchCreated = useMemo<boolean>(
    () => selectedSearches.every(isSearchCreatedTypeGuard),
    [selectedSearches],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () => isSubmitShown && isEverySearchCreated && isSearchesChanged,
    [isEverySearchCreated, isSubmitShown, isSearchesChanged],
  );

  useResetScrollPosition();

  const selectCustomSearch = (value: Search.Data["subject"]): void => {
    if (!languageId || !locationId) return;

    selectSearch({
      languageId,
      locationId,
      subject: value,
      keywordsDataSource,
      id: generateDocId(),
      status: SEARCH_DEFAULT_STATUS,
      description: SEARCH_DEFAULT_DESCRIPTION,
    });
  };

  const onSubmit = (): void => {
    if (unselectedSearches.length) {
      const unselectedSearchIds = unselectedSearches.map(({ id }) => id);

      dispatch(removeSearches(unselectedSearchIds)).unwrap().catch();
    }

    if (!selectedSearches.length) return;

    submitHandler(tracker, selectedSearches);
  };

  if (!tracker || !locationId || !languageId) return null;

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={onSubmit} className={styles.content}>
        <div className={styles.section}>
          <SelectedTracker tracker={tracker} />
        </div>
        <div className={styles.section}>
          <SuggestedSearches
            tracker={tracker}
            locationId={locationId}
            languageId={languageId}
            selectedSearches={selectedSearches}
            keywordsDataSource={keywordsDataSource}
            selectSearchHandler={selectSearch}
            unselectSearchHandler={unselectSearch}
            selectLanguageIdHandler={setLanguageId}
            selectLocationIdHandler={setLocationId}
          />
        </div>
        <div className={styles.section}>
          <SelectedSearches
            keywords={searchKeywords}
            selectedSearches={selectedSearches}
            selectSearchHandler={selectSearch}
            updateSearchHandler={updateSearch}
            unselectSearchHandler={unselectSearch}
            selectCustomSearchHandler={selectCustomSearch}
            updateSearchKeywordsHandler={updateSearchKeywords}
          />
        </div>
        {isSubmitShown && (
          <div className={styles.submit}>
            <Button
              onClick={onSubmit}
              autoFocus
              buttonSize="large"
              className={styles.submitButton}
              disabled={!isSubmitAvailable}
            >
              <span>
                {t("page.create_tracker.select_searches.form.button.submit")}
              </span>
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
