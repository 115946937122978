import { FC, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./EnterQuerySection.module.scss";
import { GREEN } from "src/constants";
import { KeyReturn } from "src/assets/icons";
import { selectApplicationInfo } from "src/store/selectors";
import { CountriesDropdown, KeywordsDataSourcesDropdown } from "src/features";
import { Button, Form, Preloader, Textarea, Transition } from "src/components";
import {
  useLanguageId,
  useLocationId,
  useUnmountEffect,
  useTemporaryErrors,
  useKeywordsDataSource,
} from "src/hooks";
import { useGetSuggestedCategories } from "../../hooks";

// Inner imports
import {
  CREATE_TRACKERS_QUERY_INPUT_LIMIT,
  CREATE_TRACKERS_QUERY_INPUT_MIN_FONT_SIZE,
  CREATE_TRACKERS_QUERY_INPUT_DEFAULT_HEIGHT,
  CREATE_TRACKERS_QUERY_INPUT_DEFAULT_FONT_SIZE,
} from "./constants";

type Props = {
  query: string;
  dashboard?: Dashboard.Data;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource | null;
  submitHandler: (value: {
    query: string;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    categories: Tracker.Category[];
    keywordsDataSource: Search.KeywordsDataSource;
  }) => void;
};

export const EnterQuerySection: FC<Props> = ({
  dashboard,
  submitHandler,
  query: defaultQuery,
  locationId: selectedLocationId,
  languageId: defaultLanguageId,
  keywordsDataSource: selectedKeywordsDataSource,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    latestTrackerConfig: {
      locationId: latestLocationId,
      keywordsDataSource: latestKeywordsDataSource,
    },
  } = useSelector(selectApplicationInfo);

  const { errors, setErrors } = useTemporaryErrors();

  const defaultLocationId = useMemo<Location.Data["id"] | null>(
    () => selectedLocationId || latestLocationId,
    [latestLocationId, selectedLocationId],
  );

  const defaultKeywordsDataSource = useMemo<Search.KeywordsDataSource | null>(
    () => selectedKeywordsDataSource || latestKeywordsDataSource,
    [latestKeywordsDataSource, selectedKeywordsDataSource],
  );

  const [keywordsDataSource, setKeywordsDataSource] = useKeywordsDataSource(
    defaultKeywordsDataSource,
  );

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: defaultLocationId,
  });

  const [languageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: defaultLanguageId,
  });

  const [query, setQuery] = useState<string>(defaultQuery);

  const [suggestedCategoriesStatus, setSuggestedCategoriesStatus] =
    useState<LoadingStatus>("idle");

  const hasDashboard = useMemo<boolean>(() => Boolean(dashboard), [dashboard]);

  const isSuggestedCategoriesLoading = useMemo<boolean>(
    () => suggestedCategoriesStatus === "loading",
    [suggestedCategoriesStatus],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () => Boolean(query && locationId && languageId && keywordsDataSource),
    [query, locationId, languageId, keywordsDataSource],
  );

  const isFooterShown = useMemo<boolean>(
    () => isSubmitAvailable || hasDashboard,
    [isSubmitAvailable, hasDashboard],
  );

  const { getSuggestedCategories, cancelGetSuggestedCategories } =
    useGetSuggestedCategories({
      status: suggestedCategoriesStatus,
      updateStatusHandler: setSuggestedCategoriesStatus,
    });

  useUnmountEffect(cancelGetSuggestedCategories);

  const onCancel = (): void => history.goBack();

  const onSubmit = (): void => {
    if (
      !isSubmitAvailable ||
      !locationId ||
      !languageId ||
      !keywordsDataSource
    ) {
      const errors: Errors = {};

      if (!keywordsDataSource)
        errors.keywordsDataSource = t(
          "page.create_tracker.enter_query.validation.data_source_required",
        );

      if (!locationId)
        errors.locationId = t(
          "page.create_tracker.enter_query.validation.location_required",
        );

      return setErrors(errors);
    }

    getSuggestedCategories({
      query,
      locationId,
      callback: (categories) =>
        submitHandler({
          query,
          locationId,
          languageId,
          categories,
          keywordsDataSource,
        }),
    }).catch();
  };

  const selectKeywordsDataSource = (value: Search.KeywordsDataSource): void => {
    setErrors((state) => ({ ...state, keywordsDataSource: "" }));

    setKeywordsDataSource(value);
  };

  const selectLocationId = (value: Location.Data["id"]): void => {
    setErrors((state) => ({ ...state, locationId: "" }));

    setLocationId(value);
  };

  return (
    <Form
      onSubmit={onSubmit}
      className={cx(
        styles.wrapper,
        isFooterShown ? styles.wrapperWithMargin : "",
      )}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>
            <span className={styles.heading}>
              {t("page.create_tracker.enter_query.heading")}
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <Textarea
            value={query}
            flexible={{
              minFontSize: CREATE_TRACKERS_QUERY_INPUT_MIN_FONT_SIZE,
              defaultHeight: CREATE_TRACKERS_QUERY_INPUT_DEFAULT_HEIGHT,
              defaultFontSize: CREATE_TRACKERS_QUERY_INPUT_DEFAULT_FONT_SIZE,
            }}
            disabled={isSuggestedCategoriesLoading}
            autoFocus
            className={styles.textareaWrapper}
            textareaClassName={styles.textarea}
            changeHandler={setQuery}
            characterLimit={CREATE_TRACKERS_QUERY_INPUT_LIMIT}
            placeholder={t(
              "page.create_tracker.enter_query.form.placeholder.analyse",
            )}
          />
        </div>
        <div className={styles.configurations}>
          <KeywordsDataSourcesDropdown
            error={errors.keywordsDataSource}
            isDisabled={isSuggestedCategoriesLoading}
            dataSource={keywordsDataSource}
            setDataSource={selectKeywordsDataSource}
          />
          <CountriesDropdown
            error={errors.locationId}
            isDisabled={isSuggestedCategoriesLoading}
            locationId={locationId}
            setLocationId={selectLocationId}
            keywordsDataSource={keywordsDataSource}
          />
        </div>
      </div>
      <Transition
        in={isFooterShown}
        classNames={{
          exit: styles.slideOutBottom,
          enter: styles.slideInBottom,
        }}
      >
        <div className={styles.footer}>
          <div>
            <div className={styles.nextStep}>
              <span>
                {t("page.create_tracker.enter_query.label.next_step")}
              </span>
              <span>
                {t("page.create_tracker.enter_query.label.select_category")}
              </span>
              {isSuggestedCategoriesLoading && (
                <Preloader type="beat" color={GREEN} />
              )}
            </div>
            <div className={styles.submit}>
              <Transition
                in={hasDashboard && !isSuggestedCategoriesLoading}
                classNames={{ exit: styles.slideOutRight }}
              >
                <Button
                  onClick={onCancel}
                  disabled={isSuggestedCategoriesLoading}
                  className={styles.cancelButton}
                  buttonSize="large"
                  buttonStyle="transparent"
                >
                  <span>
                    {t("page.create_tracker.enter_query.form.button.cancel")}
                  </span>
                </Button>
              </Transition>
              <Transition
                in={isSubmitAvailable && !isSuggestedCategoriesLoading}
                classNames={{
                  exit: styles.slideOutRight,
                  enter: isSubmitAvailable ? styles.slideInRight : "",
                }}
              >
                <Button
                  type="submit"
                  disabled={!isSubmitAvailable}
                  className={styles.submitButton}
                  buttonSize="large"
                >
                  <span>
                    {t("page.create_tracker.enter_query.form.button.submit")}
                  </span>
                  <KeyReturn />
                </Button>
              </Transition>
            </div>
          </div>
        </div>
      </Transition>
    </Form>
  );
};
