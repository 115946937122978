import { FC, memo, useState, useRef, useEffect, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./SelectWithFilter.module.scss";
import { Input, InputWithIcon } from "src/components";
import { useOutsideClickHandler } from "src/hooks";
import { getMessageIcon } from "src/utils";
import { ChevronDown } from "src/assets/icons";

// Inner imports
import { OpeningDirection, SelectWithFilterProps } from "./types";

export const SelectWithFilter: FC<SelectWithFilterProps> = memo(
  ({
    selectClassName = "",
    dropdownClassName = "",
    optionsClassName = "",
    inputClassName = "",
    options = [],
    value = "",
    changeHandler = () => "",
    placeholder = "component.select.placeholder.select_option",
    title = "",
    tabIndex = -1,
    openingDirection: _openingDirection,
    dropdownMessage,
    icon,
    isDisabled = false,
  }) => {
    const { t } = useTranslation();

    const selectRef = useRef<HTMLDivElement>(null);

    const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
      _openingDirection || "down",
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");

    const selectedOption = useMemo<Option | undefined>(
      () => options.find((option) => option.value === value),
      [options, value],
    );

    const inputValue = useMemo(
      () => selectedOption?.label || filter || "",
      [filter, selectedOption?.label],
    );

    const filteredOptions = useMemo<Option[]>(
      () =>
        options.filter((option) =>
          t(option.label.toLowerCase()).includes(filter.trim().toLowerCase()),
        ),
      [filter, options, t],
    );

    useEffect(() => {
      if (!isOpen) setFilter("");
    }, [isOpen]);

    useEffect(() => {
      if (_openingDirection) return;

      const _setOpeningDirection = () => {
        const clientHeight = document.documentElement.clientHeight;
        const selectTop = selectRef.current?.getBoundingClientRect().top!;
        setOpeningDirection(selectTop > clientHeight / 2 ? "up" : "down");
      };
      _setOpeningDirection();

      window.addEventListener("resize", _setOpeningDirection);

      return () => {
        window.removeEventListener("resize", _setOpeningDirection);
      };
    }, [_openingDirection]);

    const openingDirectionClassName: string =
      openingDirection === "up" ? "popupUp" : "popupDown";

    const onInputChange = (value: string): void => {
      changeHandler("", "");
      setFilter(value);
    };

    const onDropdownShowToggle = (): void => {
      if (isDisabled) return;

      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const onOptionSelect = (option: Option): void => {
      if (isDisabled) return;

      changeHandler(option.value, option.label);
      setIsOpen(false);
    };

    useOutsideClickHandler(selectRef, () => {
      setIsOpen(false);
    });

    return (
      <div
        className={cx(styles.selectWithFilter, selectClassName)}
        ref={selectRef}
      >
        <div
          title={title || selectedOption?.label || ""}
          className={cx(
            styles.inputWrapper,
            isDisabled ? styles.inputWrapperDisabled : "",
            inputClassName,
          )}
          onClick={onDropdownShowToggle}
        >
          {icon ? (
            <InputWithIcon
              placeholder={t(placeholder)}
              inputClassName={styles.input}
              value={inputValue}
              tabIndex={tabIndex}
              disabled={isDisabled}
              icon={icon}
              changeHandler={onInputChange}
            />
          ) : (
            <Input
              placeholder={t(placeholder)}
              inputClassName={styles.input}
              value={inputValue}
              tabIndex={tabIndex}
              disabled={isDisabled}
              changeHandler={onInputChange}
            />
          )}
          <ChevronDown
            tabIndex={-1}
            className={cx(styles.triangle, styles[isOpen ? "Open" : ""])}
          />
        </div>
        {isOpen && (
          <div
            className={cx(
              styles.popup,
              openingDirectionClassName,
              styles[openingDirectionClassName],
              dropdownClassName,
            )}
          >
            {filteredOptions.length ? (
              <div className={cx(styles.options, optionsClassName)}>
                {filteredOptions.map((option, i) => (
                  <div
                    className={styles.option}
                    onClick={() => onOptionSelect(option)}
                    title={t(option.label)}
                    key={i}
                  >
                    {t(option.label)}
                  </div>
                ))}
                {!!dropdownMessage?.text && (
                  <div
                    className={cx(styles.message, styles[dropdownMessage.type])}
                  >
                    {getMessageIcon(dropdownMessage.type)}
                    {dropdownMessage?.text}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noOptionsWrapper}>
                <div className={styles.noOptions}>
                  {t("component.select.placeholder.no_data")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
