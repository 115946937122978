import { FC, FormEvent, memo, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import cx from "classnames";

import styles from "./SignUpForm.module.scss";
import context from "src/context";
import { withError } from "src/hocs";
import {
  getHostDomain,
  validateEmail,
  isErrorTypeGuard,
  compareTwoStrings,
  showToastNotification,
} from "src/utils";
import {
  Input,
  InputPassword,
  SelectWithFilter,
  Button,
  Label,
  Translation,
} from "src/components";
import { useQueryParams, useTemporaryErrors } from "src/hooks";
import { ALL_COUNTRIES } from "src/constants";
import { useAppDispatch } from "src/store";
import { selectActiveLocations, selectWhiteLabel } from "src/store/selectors";
import { signUpUser } from "src/store/actions";
import { checkEmailAvailability } from "src/store/user/userApi";
import type { CreateUserPayload } from "src/store/actions";

const InputWithError = withError(Input);
const InputPasswordWithError = withError(InputPassword);
const SelectWithError = withError(SelectWithFilter);

type SignUpFormProps = {
  companyId?: string;
};

export const SignUpForm: FC<SignUpFormProps> = memo(({ companyId = "" }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { redirectUrl } = useQueryParams();

  const { setIsGlobalPreloaderShown } = useContext(context);

  const locations = useSelector(selectActiveLocations);

  const { id: whiteLabelId, countryCode: whiteLabelCountryCode } =
    useSelector(selectWhiteLabel);

  const { errors, setErrors } = useTemporaryErrors(3000);

  const defaultCountryCode = useMemo<string>(
    () => whiteLabelCountryCode?.toUpperCase() || "",
    [whiteLabelCountryCode],
  );

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>(defaultCountryCode);
  const [newPasswordErrors, setNewPasswordErrors] = useState<Errors>({});

  // Market section ---->
  const isCountryWarningShown = useMemo<boolean>(() => {
    if (!countryCode || !locations.length) return false;

    return !locations.some((location) =>
      compareTwoStrings(location.countryCode || "", countryCode),
    );
  }, [countryCode, locations]);

  const selectedCountryName = useMemo<string>(
    () =>
      ALL_COUNTRIES.find(({ value }) => compareTwoStrings(value, countryCode))
        ?.label || "",
    [countryCode],
  );
  // <---- Market section

  function newPasswordChangeHandler(
    value: string,
    requirementsErrors?: Errors,
  ): void {
    setPassword(value);
    requirementsErrors && setNewPasswordErrors(requirementsErrors);
  }

  async function handleSubmitForm(
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    e.preventDefault();

    try {
      setIsGlobalPreloaderShown(true);

      const errors = await validate();

      if (Object.keys(errors).length) return setErrors(errors);

      const userId = await dispatch(signUpUser(formatSignUpPayload())).unwrap();

      if (userId) history.push(`/verify/${userId}`);
    } catch (error) {
      let message = "";

      if (error instanceof AxiosError) message = error.response?.data?.error;

      if (isErrorTypeGuard(error)) message = error.message;

      showToastNotification({ type: "error", text: message });
    } finally {
      setIsGlobalPreloaderShown(false);
    }
  }

  async function validate(): Promise<Errors> {
    const validationErrors: typeof errors = {};

    if (!firstName.trim().length)
      validationErrors.firstName = t(
        "authentication.signup.form.validation.required",
      );

    if (!lastName.trim().length)
      validationErrors.lastName = t(
        "authentication.signup.form.validation.required",
      );

    const isEmailValid = validateEmail(email);

    if (!isEmailValid)
      validationErrors.email = t(
        "authentication.signup.form.validation.invalid_email",
      );

    const isEmailAvailable = isEmailValid
      ? await checkEmailAvailability(email.trim())
      : true;

    if (!isEmailAvailable)
      validationErrors.email = t(
        "authentication.signup.form.validation.email_exists",
      );

    if (!email.trim().length)
      validationErrors.email = t(
        "authentication.signup.form.validation.required",
      );

    if (!!Object.entries(newPasswordErrors).length) {
      validationErrors.password = t(
        "authentication.signup.form.validation.invalid_password",
      );
    }

    if (!password.trim().length) {
      validationErrors.password = t(
        "authentication.signup.form.validation.required",
      );
    }

    // This fields validate only if user is registering NOT through company link
    if (!companyId) {
      if (!companyName.trim().length)
        validationErrors.companyName = t(
          "authentication.signup.form.validation.required",
        );

      if (!countryCode.trim().length)
        validationErrors.locationCode = t(
          "authentication.signup.form.validation.required",
        );
    }

    return validationErrors;
  }

  function formatSignUpPayload(): CreateUserPayload {
    const user = {
      email: email.trim(),
      password: password.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      ...(companyId ? { companyId } : {}),
    };

    /* If companyId exists it means that user is registering through company link */
    /* User automatically will be added to existing company therefore company object not needed */
    const company = !companyId && {
      name: companyName,
      countryCode,
    };

    return {
      user,
      whiteLabel: whiteLabelId,
      whiteLabelDomainId: getHostDomain(),
      ...(company ? { company } : {}),
      ...(redirectUrl ? { redirectUrl } : {}),
    };
  }

  return (
    <div className={styles.wrapper}>
      <form
        name="signUp"
        autoComplete="off"
        className={styles.formWrapper}
        onSubmit={handleSubmitForm}
      >
        <div className={styles.inputsWrapper}>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("authentication.signup.form.label.first_name")}
              className={styles.label}
            />
            <InputWithError
              className={styles.input}
              value={firstName}
              changeHandler={setFirstName}
              placeholder={t(
                "authentication.signup.form.placeholder.first_name",
              )}
              error={errors.firstName}
              maxLength={50}
              tabIndex={1}
              autoFocus
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("authentication.signup.form.label.last_name")}
              className={styles.label}
            />
            <InputWithError
              className={styles.input}
              value={lastName}
              changeHandler={setLastName}
              placeholder={t(
                "authentication.signup.form.placeholder.last_name",
              )}
              error={errors.lastName}
              maxLength={50}
              tabIndex={2}
            />
          </div>
          <div className={styles.inputWrapperFull}>
            <Label
              leftText={t("authentication.signup.form.label.email")}
              className={styles.label}
            />
            <InputWithError
              className={cx(styles.input, styles.fullSize)}
              value={email}
              changeHandler={setEmail}
              placeholder={t("authentication.signup.form.placeholder.email")}
              error={errors.email}
              tabIndex={3}
            />
          </div>
          <div className={styles.inputWrapperFull}>
            <Label
              leftText={t("authentication.signup.form.label.password")}
              className={styles.label}
            />
            <InputPasswordWithError
              className={cx(styles.input, styles.fullSize)}
              value={password}
              onChangeHandler={newPasswordChangeHandler}
              isPasswordRequirementsShow
              error={errors.password}
              placeholder={t("authentication.signup.form.placeholder.password")}
              tabIndex={4}
            />
          </div>
          {/* It means that user is registering not through company link and this fields not needed */}
          {!companyId && (
            <>
              <div className={styles.inputWrapper}>
                <Label
                  leftText={t("authentication.signup.form.label.company_name")}
                  className={styles.label}
                />
                <InputWithError
                  className={styles.input}
                  value={companyName}
                  maxLength={120}
                  changeHandler={setCompanyName}
                  placeholder={t(
                    "authentication.signup.form.placeholder.company_name",
                  )}
                  error={errors.companyName}
                  tabIndex={6}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Label
                  leftText={t("authentication.signup.form.label.location")}
                  className={styles.label}
                />
                <SelectWithError
                  selectClassName={styles.selectInput}
                  placeholder={t(
                    "authentication.signup.form.placeholder.location",
                  )}
                  tabIndex={7}
                  value={countryCode}
                  options={ALL_COUNTRIES}
                  changeHandler={setCountryCode}
                  error={errors.locationCode}
                />
              </div>
            </>
          )}
          {isCountryWarningShown && (
            <div className={styles.selectedCountryWarning}>
              <Translation
                i18nKey="authentication.signup.status.warning.not_supported_location"
                values={{ selectedCountryName }}
              />
            </div>
          )}
        </div>
        <Button className={styles.button} type="submit" tabIndex={10}>
          {t("authentication.signup.form.button.submit")}
        </Button>
      </form>
    </div>
  );
});
