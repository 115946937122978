import { ForwardedRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../WidgetTile.module.scss";
import { useGlobalPreloader } from "src/hooks";
import { WIDGET_EXPORT_NAME } from "src/constants";
import {
  selectWidgetData,
  selectDashboardById,
  selectDashboardDateRangeById,
} from "src/store/selectors";
import {
  triggerGtmEvent,
  downloadHTMLImage,
  showToastNotification,
  DownloadHTMLImageFormat,
  customRequestIdleCallback,
} from "src/utils";

// Inner imports
import { WIDGET_EXPORT_IMAGE } from "../constants";

export const useExportWidgetImage = ({
  ref,
  widgetId,
  trackersCollectionId,
  dashboardDateRangeId,
}: {
  widgetId: Widget.IdType;
  ref: ForwardedRef<HTMLElement>;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}) => {
  const { t } = useTranslation();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, widgetId),
  );

  const hasWidgetImageExport = useMemo<boolean>(
    () =>
      Boolean(dashboard && dashboardDateRange) &&
      WIDGET_EXPORT_IMAGE.includes(widgetId),
    [dashboard, dashboardDateRange, widgetId],
  );

  const [startDate, endDate] = useMemo<[ISODateString, ISODateString]>(() => {
    if (!widgetData) return ["", ""];

    return [widgetData.startDate, widgetData.endDate];
  }, [widgetData]);

  const exportWidgetImage = async (
    format: DownloadHTMLImageFormat,
  ): Promise<void> => {
    const element = ref && "current" in ref ? ref.current : null;

    if (!dashboard || !dashboardDateRange || !element) return;

    const imageName = generateExportWidgetImageName({
      endDate,
      widgetId,
      dashboard,
      startDate,
    });

    showGlobalPreloader();

    if (styles.snapshot) element.classList.add(styles.snapshot);

    customRequestIdleCallback(
      () =>
        downloadHTMLImage({ format, name: imageName, element })
          .then(() => {
            triggerGtmEvent("WidgetDownloadImage", { widgetId });

            if (styles.snapshot) element.classList.remove(styles.snapshot);

            hideGlobalPreloader();
          })
          .catch((error) => {
            console.error(error);

            showToastNotification({
              type: "error",
              text: t("common.error.server_error"),
            });
          })
          .finally(() => {
            if (styles.snapshot) element.classList.remove(styles.snapshot);

            hideGlobalPreloader();
          }),
      100,
    );
  };

  return { hasWidgetImageExport, exportWidgetImage };
};

function generateExportWidgetImageName({
  endDate,
  widgetId,
  startDate,
  dashboard,
}: {
  endDate: ISODateString;
  widgetId: Widget.IdType;
  startDate: ISODateString;
  dashboard: Dashboard.Data;
}): string {
  const fileName: string[] = [];

  const dashboardName = dashboard.name;

  if (dashboardName) fileName.push(dashboardName);

  const widgetExportName = WIDGET_EXPORT_NAME[widgetId];

  if (widgetExportName) fileName.push(`[${widgetExportName}]`);

  if (startDate && endDate) fileName.push(`[${startDate} ${endDate}]`);

  const currentDate = new Date().toISOString();

  fileName.push(currentDate);

  return fileName.join(" ");
}
