import {
  FC,
  useRef,
  useMemo,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./MenuDropdownSubmenu.module.scss";
import { getIconByName, isAppIconTypeGuard } from "src/utils";

// Inner imports
import { calculateSubmenuDirection } from "./utils";
import {
  MenuDropdownSubmenuProps,
  MenuDropdownSubmenuDirection,
} from "./types";
import { MenuOption } from "../../types";

export const MenuDropdownSubmenu: FC<MenuDropdownSubmenuProps> = ({
  option,
  callback,
  className,
  hasSingleOptionAsSubmenu,
  direction: defaultDirection = null,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const [isShown, setIsShown] = useState<boolean>(false);

  const [direction, setDirection] =
    useState<MenuDropdownSubmenuDirection | null>(defaultDirection);

  const optionsLength = useMemo<number>(
    () => option.options?.length || 0,
    [option.options?.length],
  );

  const firstMenuOption = useMemo<MenuOption | undefined>(
    () => option.options?.[0],
    [option.options],
  );

  const hasMenu = useMemo<boolean>(
    () => Boolean(optionsLength),
    [optionsLength],
  );

  const Icon = useMemo<JSX.Element | null | undefined>(
    () =>
      isAppIconTypeGuard(option.icon)
        ? getIconByName(option.icon)
        : option.icon,
    [option.icon],
  );

  const optionsPositionClassName = useMemo<string>(() => {
    if (!direction) return "";

    return styles[direction] || "";
  }, [direction]);

  const onOptionClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, option: MenuOption): void => {
      option.onClick?.(event);

      callback();
    },
    [callback],
  );

  const renderOption = useCallback(
    (option: MenuOption): JSX.Element => {
      const Icon = isAppIconTypeGuard(option.icon)
        ? getIconByName(option.icon)
        : option.icon;

      return (
        <button
          key={option.label}
          type="button"
          className={styles.option}
          onClick={(event) => onOptionClick(event, option)}
        >
          {Icon && <div className={styles.icon}>{Icon}</div>}
          <span className={styles.label} title={option.label}>
            {option.label}
          </span>
        </button>
      );
    },
    [onOptionClick],
  );

  useEffect(() => {
    if (defaultDirection) return;

    const element = ref.current;

    if (!element) return;

    const direction = calculateSubmenuDirection(element);

    setDirection(direction);
  }, [defaultDirection]);

  const onMouseEnter = (): void => setIsShown(true);

  const onMouseLeave = (): void => setIsShown(false);

  if (!hasSingleOptionAsSubmenu && optionsLength <= 1 && firstMenuOption) {
    const label = t("component.menu_dropdown.label.option", {
      optionLabel: option.label,
      subOptionLabel: firstMenuOption.label,
    });

    return (
      <button
        type="button"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx(styles.wrapper, className)}
        onClick={(event) => onOptionClick(event, firstMenuOption)}
      >
        {Icon && <div className={styles.icon}>{Icon}</div>}
        <span className={styles.label} title={label}>
          {label}
        </span>
      </button>
    );
  }

  return (
    <div
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(styles.wrapper, className)}
    >
      {Icon && <div className={styles.icon}>{Icon}</div>}
      <span className={styles.label} title={option.label}>
        {option.label}
      </span>
      {hasMenu && isShown && (
        <div className={cx(styles.options, optionsPositionClassName)}>
          {option.options?.map(renderOption)}
        </div>
      )}
    </div>
  );
};
