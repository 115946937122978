import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTracker.module.scss";
import { useAppDispatch } from "src/store";
import { createSearch } from "src/store/actions";
import { useModal, useScrollTo } from "src/hooks";
import { MenuOption } from "src/components/MenuDropdown/types";
import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "src/constants";
import { Preloader, MenuDropdown, Translation } from "src/components";
import { getKeywordSearchLink, showToastNotification } from "src/utils";
import { selectLocationById, selectLanguageById } from "src/store/selectors";
import { getDefaultSuggestedSearch } from "src/pages/Trackers/CreateTrackers/utils";
import {
  EditTrackerModal,
  DuplicateTrackerModal,
  TrackerKeywordsDataSourcesIcon,
} from "src/features";
import { useGetTrackerDescription } from "../../../../../../hooks";

type Props = {
  tracker: Tracker.CreationData;
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Search.CreationData[];
  resetSearchesHandler: (tracker: Tracker.CreationData) => void;
  unselectTrackerHandler: (tracker: Tracker.CreationData) => void;
  duplicateTrackerHandler: (tracker: Tracker.CreationData) => void;
  openTrackerSettingsHandler: (tracker: Tracker.CreationData) => void;
  updateTrackerHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    callback?: () => void;
    changes: Tracker.CreationData;
  }) => void;
  selectSearchHandler: (
    tracker: Tracker.CreationData,
    search: Search.CreationData,
  ) => void;
};

export const SelectedTracker = forwardRef(
  (
    {
      tracker,
      selectedSearches,
      selectedTrackers,
      selectSearchHandler,
      updateTrackerHandler,
      resetSearchesHandler,
      unselectTrackerHandler,
      duplicateTrackerHandler,
      openTrackerSettingsHandler,
    }: Props,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { setModal } = useModal();

    const [ref, setShouldScrollTo] = useScrollTo<HTMLDivElement>({
      behavior: "smooth",
    });

    const location = useSelector((state: Store.RootState) =>
      selectLocationById(state, tracker.locationId),
    );

    const language = useSelector((state: Store.RootState) =>
      selectLanguageById(state, tracker.languageId),
    );

    const [searchCreateLoadingStatus, setSearchCreateLoadingStatus] =
      useState<LoadingStatus>("idle");

    const [
      trackerDescriptionLoadingStatus,
      setTrackerDescriptionLoadingStatus,
    ] = useState<LoadingStatus>("idle");

    const { getTrackerDescription } = useGetTrackerDescription({
      tracker,
      location,
      selectedTrackers,
      updateStatusHandler: setTrackerDescriptionLoadingStatus,
    });

    const locationName = useMemo<string>(
      () => location?.name || "",
      [location?.name],
    );

    const languageName = useMemo<string>(
      () => language?.name || "",
      [language?.name],
    );

    const { searchLink, imageSearchLink } = useMemo(
      () => ({
        searchLink: getKeywordSearchLink({ keyword: tracker.name, location }),
        imageSearchLink: getKeywordSearchLink({
          location,
          type: "image",
          keyword: tracker.name,
        }),
      }),
      [tracker.name, location],
    );

    const isSearchCreateLoading = useMemo<boolean>(
      () => searchCreateLoadingStatus === "loading",
      [searchCreateLoadingStatus],
    );

    const isTrackerDescriptionLoading = useMemo<boolean>(
      () => trackerDescriptionLoadingStatus === "loading",
      [trackerDescriptionLoadingStatus],
    );

    const isLoading = useMemo<boolean>(
      () => isSearchCreateLoading || isTrackerDescriptionLoading,
      [isSearchCreateLoading, isTrackerDescriptionLoading],
    );

    useEffect(() => {
      if (
        searchCreateLoadingStatus !== "idle" ||
        selectedSearches.length ||
        !tracker.description
      )
        return;

      const defaultSearch = getDefaultSuggestedSearch({
        subject: tracker.name,
        locationId: tracker.locationId,
        languageId: tracker.languageId,
        description: tracker.description,
        keywordsDataSource:
          tracker.keywordsDataSources[0] || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
      });

      setSearchCreateLoadingStatus("loading");

      dispatch(createSearch(defaultSearch))
        .unwrap()
        .then((newSearch) => {
          selectSearchHandler(tracker, newSearch);

          setSearchCreateLoadingStatus("succeeded");
        })
        .catch((error) => {
          console.error(error);

          showToastNotification({
            type: "error",
            text: t("common.error.server_error"),
          });

          setSearchCreateLoadingStatus("failed");
        });
    }, [
      t,
      tracker,
      dispatch,
      selectSearchHandler,
      selectedSearches.length,
      searchCreateLoadingStatus,
    ]);

    useEffect(() => {
      if (tracker.description || trackerDescriptionLoadingStatus !== "idle")
        return;

      getTrackerDescription().then((value) => {
        updateTrackerHandler({
          id: tracker.id,
          changes: { ...tracker, description: value },
        });

        if (value) return;

        showToastNotification({
          type: "warning",
          text: (
            <Translation
              values={{ name: tracker.name }}
              i18nKey="component.selected_tracker.status.warning.tracker_removed"
            />
          ),
        });

        unselectTrackerHandler(tracker);
      });
    }, [
      tracker,
      updateTrackerHandler,
      getTrackerDescription,
      unselectTrackerHandler,
      trackerDescriptionLoadingStatus,
    ]);

    useEffect(() => {
      setShouldScrollTo(true);
    }, [setShouldScrollTo]);

    const onEditSubmit = useCallback(
      ({
        id,
        changes,
        callback,
      }: {
        id: Tracker.CreationData["id"];
        callback: () => void;
        changes: Tracker.CreationData;
      }): void =>
        updateTrackerHandler({
          id,
          changes,
          callback: () => {
            resetSearchesHandler(changes);

            setSearchCreateLoadingStatus("idle");

            callback();
          },
        }),
      [resetSearchesHandler, updateTrackerHandler],
    );

    const showSearch = useCallback((): void => {
      window.open(searchLink, "_blank");
    }, [searchLink]);

    const showImageSearch = useCallback((): void => {
      window.open(imageSearchLink, "_blank");
    }, [imageSearchLink]);

    const showEditTrackerModal = useCallback(
      (): void =>
        setModal(
          "edit-tracker",
          <EditTrackerModal tracker={tracker} submitHandler={onEditSubmit} />,
        ),
      [setModal, tracker, onEditSubmit],
    );

    const showDuplicateTrackerModal = useCallback(
      (): void =>
        setModal(
          "duplicate-tracker",
          <DuplicateTrackerModal
            tracker={tracker}
            submitHandler={duplicateTrackerHandler}
          />,
        ),
      [setModal, tracker, duplicateTrackerHandler],
    );

    const openTrackerSettings = useCallback(
      (): void => openTrackerSettingsHandler(tracker),
      [openTrackerSettingsHandler, tracker],
    );

    const unselectTracker = useCallback(
      (): void => unselectTrackerHandler(tracker),
      [tracker, unselectTrackerHandler],
    );

    const options = useMemo<MenuOption[]>(
      () => [
        {
          group: 1,
          icon: "DuplicateOutline",
          onClick: showDuplicateTrackerModal,
          label: t("component.selected_tracker.label.duplicate_tracker"),
        },
        {
          group: 1,
          icon: "PencilOutline",
          onClick: showEditTrackerModal,
          label: t("component.selected_tracker.label.edit_tracker"),
        },
        {
          group: 1,
          icon: "GearOutline",
          onClick: openTrackerSettings,
          label: t("component.selected_tracker.label.settings"),
        },
        {
          group: 2,
          icon: "Search",
          onClick: showSearch,
          label: t("component.selected_tracker.label.search_link"),
        },
        {
          group: 2,
          icon: "ImageSearch",
          onClick: showImageSearch,
          label: t("component.selected_tracker.label.image_search_link"),
        },
        {
          group: 3,
          type: "danger",
          icon: "TrashOutline",
          onClick: unselectTracker,
          label: t("component.selected_tracker.label.unselect_tracker"),
        },
      ],
      [
        t,
        showSearch,
        unselectTracker,
        showImageSearch,
        openTrackerSettings,
        showEditTrackerModal,
        showDuplicateTrackerModal,
      ],
    );

    const onClick = (): void => {
      if (!isSearchCreateLoading) openTrackerSettings();
    };

    return (
      <div className={styles.wrapper} onClick={onClick} ref={ref}>
        {isLoading && (
          <div className={styles.loader}>
            <Preloader
              type="bar"
              text={t("component.selected_tracker.loader.create_search")}
            />
          </div>
        )}
        <div className={styles.header}>
          <span className={styles.heading}>
            {t("component.selected_tracker.label.tracker")}
          </span>
          <div className={styles.settings}>
            <MenuDropdown
              ref={forwardedRef}
              options={options}
              className={styles.setting}
              isEventPropagationStopped
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <span>{tracker.name}</span>
          </div>
          <div className={styles.description}>
            <span>{tracker.description}</span>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.keywordsDataSource}>
            <TrackerKeywordsDataSourcesIcon
              keywordsDataSources={tracker.keywordsDataSources}
            />
          </div>
          <div className={styles.location} title={locationName}>
            <span>{locationName}</span>
          </div>
          <div className={styles.language} title={languageName}>
            <span>{tracker.languageId.toUpperCase()}</span>
          </div>
        </div>
      </div>
    );
  },
);
