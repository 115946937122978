import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import store from "./store";
import ContextProvider from "./context/ContextProvider";

import "./index.scss";

import "src/services/firebase";
import "src/services/i18nLocalization";
import "src/services/googleTagManager";

ReactDOM.render(
  <>
    <Provider store={store}>
      <BrowserRouter getUserConfirmation={() => {}}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </Provider>
  </>,
  document.getElementById("root"),
);
