import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectTrackerSearches.module.scss";
import { useModal } from "src/hooks";
import { ConfirmModal } from "src/features";
import { SelectSearchesSection } from "src/pages/Trackers/CreateTrackers/components";

type Props = {
  tracker: Tracker.CreationData;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  submitHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
};

export const SelectTrackerSearchesModal: FC<Props> = (props) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const submitHandler = (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => {
    props.submitHandler(tracker, searches);

    closeModal("select-tracker-searches");
  };

  return (
    <ConfirmModal
      id="select-tracker-searches"
      type="success"
      title={t("component.modal.select_tracker_searches.title")}
      className={styles.wrapper}
    >
      <SelectSearchesSection {...props} submitHandler={submitHandler} />
    </ConfirmModal>
  );
};
