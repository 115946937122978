import { DEFAULT_LANGUAGE_ID } from "src/constants";

export const getLocationDefaultLanguageId = (
  locationId: Location.Data["id"],
  locations: Location.Data[],
  languages: Language.Data[],
): Language.Data["id"] => {
  if (!locationId) return DEFAULT_LANGUAGE_ID;

  const location = locations.find(({ id }) => locationId === id);

  if (!location) return DEFAULT_LANGUAGE_ID;

  const locationDefaultLanguageId = location.languageIds[0];

  if (!locationDefaultLanguageId) return DEFAULT_LANGUAGE_ID;

  const language = languages.find(({ id }) => locationDefaultLanguageId === id);

  if (!language) return DEFAULT_LANGUAGE_ID;

  return language.id;
};
