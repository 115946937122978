import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectLocationsEntities } from "src/store/selectors";

type LocationName = {
  name: string;
  preciseName: string;
};

export const useTrackerLocation = (
  searches: Array<Search.Data | Search.CreationData>,
): LocationName => {
  const { t } = useTranslation();

  const locations = useSelector(selectLocationsEntities);

  return useMemo<LocationName>(() => {
    const locationIds = new Set<Location.Data["id"]>();

    for (const { locationId } of searches) {
      const location = locations[locationId];

      if (location) locationIds.add(locationId);
    }

    if (locationIds.size > 1)
      return {
        name: t("tracker.label.location_mixed"),
        preciseName: t("tracker.label.location_mixed"),
      };

    const locationId = [...locationIds][0];

    if (!locationId) return { name: "", preciseName: "" };

    const location = locations[locationId];

    if (!location) return { name: "", preciseName: "" };

    const [name, preciseName] = [
      location.city || location.region || location.country || location.name,
      location.name,
    ];

    return { name, preciseName };
  }, [locations, searches, t]);
};
