import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { selectCompanyId } from "../company/companySelector";
import { selectUserId } from "../user/userSelector";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./trackersApi";
import { selectTrackersWithSearchId } from "./trackersSelector";

export const trackersAdapter = createEntityAdapter<Tracker.Data>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = trackersAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchTrackersByCompanyId = createAsyncThunk<
  Tracker.Data[],
  Company.Data["id"]
>("trackers/fetch-by-company-id", api.getTrackersByCompanyId);

export const fetchTrackersByIds = createAsyncThunk<
  Tracker.Data[],
  Tracker.Data["id"][]
>("trackers/fetch-by-ids", api.getTrackersByIds);

export const fetchTrackersByTrackersCollectionId = createAsyncThunk<
  Tracker.Data[],
  TrackersCollection.Data["id"]
>(
  "trackers/fetch-by-trackers-collection-id",
  api.getTrackersByTrackersCollectionId,
);

export const createTracker = createAsyncThunk<
  Tracker.Data,
  Store.CreateEntity<
    Pick<
      Tracker.Data,
      "name" | "searchIds" | "category" | "description" | "keywordsDataSources"
    >
  >,
  { state: Store.RootState }
>("trackers/create-one", async (payload, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const userId = selectUserId(state);

  const _payload = { ...payload, authorId: userId, companyId, tags: [] };

  return api.createTracker(_payload);
});

export const updateTracker = createAsyncThunk<
  Store.UpdateEntity<Tracker.Data>,
  Store.UpdateEntity<Tracker.Data>,
  { state: Store.RootState }
>("trackers/update-one", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateTracker(payload, companyId);
});

export const updateTrackers = createAsyncThunk<
  Store.UpdateEntity<Tracker.Data>[],
  Store.UpdateEntity<Tracker.Data>[]
>("trackers/update-many", api.updateTrackers);

export const updateTrackersByAuthorId = createAsyncThunk<
  Store.UpdateEntity<Tracker.Data>[],
  {
    changes: Store.UpdateEntity<Tracker.Data>["changes"];
    authorId: Tracker.Data["authorId"];
  },
  { state: Store.RootState }
>("trackers/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateTrackersByAuthorId(payload, companyId);
});

export const removeTracker = createAsyncThunk<
  Tracker.Data["id"],
  Tracker.Data["id"]
>("trackers/remove-one", async (payload) => {
  await api.deleteTracker(payload);

  return payload;
});

export const removeTrackers = createAsyncThunk<
  Tracker.Data["id"][],
  Tracker.Data["id"][]
>("trackers/remove-many", async (payload) => {
  await api.deleteTrackers(payload);

  return payload;
});

export const cleanTrackersSearchIds = createAsyncThunk<
  Store.UpdateEntity<Tracker.Data>[],
  Search.Data["id"],
  { state: Store.RootState }
>("trackers/clean-search-ids", (searchId, { getState }) => {
  const state = getState();

  const trackers = selectTrackersWithSearchId(state, searchId);

  const payload: Store.UpdateEntity<Tracker.Data>[] = [];

  for (const tracker of trackers) {
    payload.push({
      id: tracker.id,
      changes: {
        searchIds: tracker.searchIds.filter((id) => id !== searchId),
      },
    });
  }

  return payload;
});

export const duplicateDashboardTrackers = createAsyncThunk<
  Tracker.Data[],
  Dashboard.Data["id"],
  { state: Store.RootState }
>("trackers/duplicate-dashboard-trackers", (dashboardId, { getState }) => {
  const state = getState();

  const [companyId, userId] = [selectCompanyId(state), selectUserId(state)];

  return api.duplicateDashboardTrackers({ userId, companyId, dashboardId });
});

const trackersSlice = createSlice({
  name: "trackers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrackersByCompanyId.pending, onStatePending);
    builder.addCase(fetchTrackersByCompanyId.rejected, onStateRejected);
    builder.addCase(fetchTrackersByCompanyId.fulfilled, (...args) => {
      trackersAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(fetchTrackersByIds.fulfilled, trackersAdapter.upsertMany);

    builder.addCase(
      fetchTrackersByTrackersCollectionId.fulfilled,
      trackersAdapter.upsertMany,
    );

    builder.addCase(createTracker.fulfilled, trackersAdapter.addOne);

    builder.addCase(updateTracker.fulfilled, trackersAdapter.updateOne);

    builder.addCase(updateTrackers.fulfilled, trackersAdapter.updateMany);

    builder.addCase(
      updateTrackersByAuthorId.fulfilled,
      trackersAdapter.updateMany,
    );

    builder.addCase(removeTracker.fulfilled, trackersAdapter.removeOne);

    builder.addCase(removeTrackers.fulfilled, trackersAdapter.removeMany);

    builder.addCase(
      cleanTrackersSearchIds.fulfilled,
      trackersAdapter.updateMany,
    );

    builder.addCase(
      duplicateDashboardTrackers.fulfilled,
      trackersAdapter.addMany,
    );
  },
});

export default trackersSlice.reducer;
