import { FC, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import styles from "./EditTrackerModal.module.scss";
import { withError } from "src/hocs";
import { getDifferenceInObjects } from "src/utils";
import { Alert, Form, Input, Label } from "src/components";
import {
  TRACKER_INPUT_LIMIT,
  DEFAULT_LOCATION_ID,
  DEFAULT_LANGUAGE_ID,
} from "src/constants";
import {
  CountriesDropdown,
  LanguagesDropdown,
  KeywordsDataSourcesDropdown,
} from "src/features";
import {
  useModal,
  useLocationId,
  useLanguageId,
  useElementFocus,
  useTemporaryErrors,
} from "src/hooks";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

type Props = {
  tracker: Tracker.CreationData;
  submitHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    callback: () => void;
    changes: Tracker.CreationData;
  }) => void;
};

export const EditTrackerModal: FC<Props> = ({
  submitHandler,
  tracker: defaultTracker,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [tracker, setTracker] = useImmer<Tracker.CreationData>(defaultTracker);

  const keywordsDataSource = useMemo<Nullable<Search.KeywordsDataSource>>(
    () => tracker.keywordsDataSources[0] || null,
    [tracker.keywordsDataSources],
  );

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: tracker.locationId,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: tracker.languageId,
  });

  const formattedTracker = useMemo<Tracker.CreationData>(
    () => ({
      ...tracker,
      locationId: locationId || DEFAULT_LOCATION_ID,
      languageId: languageId || DEFAULT_LANGUAGE_ID,
    }),
    [languageId, locationId, tracker],
  );

  const isTrackersConfigurationChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(formattedTracker, defaultTracker)),
    [defaultTracker, formattedTracker],
  );

  const isDisabled = useMemo<boolean>(
    () => !isTrackersConfigurationChanged,
    [isTrackersConfigurationChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: Tracker.CreationData["name"]): void =>
    setTracker((draft) => {
      draft.name = value;
    });

  const onDescriptionChange = (
    value: Tracker.CreationData["description"],
  ): void =>
    setTracker((draft) => {
      draft.description = value;
    });

  const onCategoryChange = (value: Tracker.CreationData["category"]): void =>
    setTracker((draft) => {
      draft.category = value;
    });

  const onLocationIdChange = (value: string): void => setLocationId(value);

  const onLanguageIdChange = (value: string): void => setLanguageId(value);

  const onKeywordsDataSourceChange = (value: Search.KeywordsDataSource): void =>
    setTracker((draft) => {
      draft.keywordsDataSources = [value];
    });

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler({
      callback: () => closeModal("edit-tracker"),
      id: formattedTracker.id,
      changes: formattedTracker,
    });
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { name, description = "", category } = formattedTracker;

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.edit_tracker.form.validation.name_required",
      );

    if (!description.trim().length)
      validationErrors.description = t(
        "component.modal.edit_tracker.form.validation.description_required",
      );

    if (!category.trim().length)
      validationErrors.category = t(
        "component.modal.edit_tracker.form.validation.category_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="edit-tracker"
      type="success"
      acceptButton={{
        onClick: onSubmit,
        disabled: isDisabled,
        text: t("component.modal.edit_tracker.button.submit"),
      }}
      cancelButton={{
        onClick: () => closeModal("edit-tracker"),
        text: t("component.modal.edit_tracker.button.cancel"),
      }}
      title={t("component.modal.edit_tracker.title")}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label leftText={t("component.modal.edit_tracker.form.label.name")} />
          <InputWithError
            ref={ref}
            error={errors.name}
            changeHandler={onNameChange}
            value={formattedTracker.name}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.edit_tracker.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.edit_tracker.form.label.description")}
          />
          <InputWithError
            error={errors.description}
            changeHandler={onDescriptionChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            value={formattedTracker.description}
            placeholder={t(
              "component.modal.edit_tracker.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.edit_tracker.form.label.keywords_data_source",
            )}
          />
          <KeywordsDataSourcesDropdown
            hasDefaultStyles
            dataSource={keywordsDataSource}
            setDataSource={onKeywordsDataSourceChange}
          />
        </div>
        <div className={styles.groupWrapper}>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.edit_tracker.form.label.location")}
            />
            <CountriesDropdown
              hasDefaultStyles
              openingDirection="bottom-end"
              setLocationId={onLocationIdChange}
              keywordsDataSource={keywordsDataSource}
              locationId={formattedTracker.locationId}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.edit_tracker.form.label.language")}
            />
            <LanguagesDropdown
              hasDefaultStyles
              openingDirection="bottom-end"
              setLanguageId={onLanguageIdChange}
              keywordsDataSource={keywordsDataSource}
              languageId={formattedTracker.languageId}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.edit_tracker.form.label.category")}
          />
          <InputWithError
            error={errors.category}
            changeHandler={onCategoryChange}
            value={formattedTracker.category}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.edit_tracker.form.placeholder.category",
            )}
          />
        </div>
        <Alert
          type="info"
          message={t(
            "component.modal.edit_tracker.status.warning.tracker_update",
          )}
        />
      </Form>
    </ConfirmModal>
  );
};
