import { FC, useMemo } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./CountriesDropdown.module.scss";
import { Select, Tooltip } from "src/components";
import { SelectProps } from "src/components/selectors/Select/types";
import { selectLocationsByKeywordsDataSource } from "src/store/selectors";

type Props = Pick<SelectProps, "openingDirection"> & {
  error?: string;
  locationId: Nullable<Location.Data["id"]>;
  isDisabled?: boolean;
  setLocationId: (value: Location.Data["id"]) => void;
  hasDefaultStyles?: boolean;
  keywordsDataSource: Nullable<Search.KeywordsDataSource>;
};

export const CountriesDropdown: FC<Props> = ({
  error,
  locationId,
  setLocationId,
  keywordsDataSource,
  hasDefaultStyles = false,
  openingDirection = "bottom-end",
  isDisabled: defaultIsDisabled = false,
}) => {
  const { t } = useTranslation();

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const locationsOptions = useMemo<Option<Location.Data["id"]>[]>(
    () =>
      locations.map((location) => ({
        value: location.id,
        label: location.name,
      })),
    [locations],
  );

  const hasOneOption = useMemo<boolean>(
    () => locationsOptions.length === 1,
    [locationsOptions.length],
  );

  const isDisabled = useMemo<boolean>(() => {
    if (defaultIsDisabled) return defaultIsDisabled;

    return Boolean(locationId) && hasOneOption;
  }, [defaultIsDisabled, hasOneOption, locationId]);

  const tooltip = useMemo<string>(() => {
    if (hasOneOption && isDisabled)
      return t("component.countries_dropdown.tooltip.one_option.single_option");

    return "";
  }, [hasOneOption, t, isDisabled]);

  const icon = useMemo<Nullable<AppIcon>>(() => {
    if (locationId) return null;

    return "Public";
  }, [locationId]);

  const onLocationSelect = (value: Location.Data["id"]): void =>
    setLocationId(value);

  return (
    <div className={hasDefaultStyles ? "" : styles.wrapper}>
      <Tooltip content={tooltip}>
        <Select
          icon={icon}
          value={locationId || ""}
          options={locationsOptions}
          hasFilter
          isDisabled={isDisabled}
          changeHandler={onLocationSelect}
          openingDirection={openingDirection}
          title={t("component.keywords_data_sources.title")}
          placeholder={t("component.countries_dropdown.placeholder")}
          inputClassName={hasDefaultStyles ? "" : styles.input}
          selectClassName={cx(
            hasDefaultStyles ? "" : styles.select,
            error ? styles.selectError : "",
          )}
          dropdownClassName={hasDefaultStyles ? "" : styles.dropdown}
        />
      </Tooltip>
      {Boolean(error) && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
