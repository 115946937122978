export const LOCATION_TYPES = [
  "All Countries",
  "Autonomous Community",
  "Canton",
  "City",
  "Congressional District",
  "Country",
  "County",
  "Department",
  "Governorate",
  "Municipality",
  "Prefecture",
  "Province",
  "Region",
  "State",
  "Territory",
  "Union Territory",
] as const;

export const LOCATION_WEBSITE_DOMAIN_EXCEPTION: Record<string, string> = {
  GB: "UK",
  GLB: "",
};

// Worldwide
export const PRIORITIZED_LOCATION_ID = "r6BhTZkbFkDGyojmPiPD" as const;

// Worldwide
export const DEFAULT_LOCATION_ID = "r6BhTZkbFkDGyojmPiPD" as const;
